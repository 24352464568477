import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import Modal from "react-bootstrap/Modal";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import ChoiceContainer from "components/choices/Container";
import { useCreateGiftPurchaseMutation } from "services/projectCity";
import useRedirectToCheckout from "features/subscriptions/hooks/RedirectToCheckout";
import InfoImage from "images/icons/info.svg";
import Body from "components/text/Body";

function SubscriptionGiftModal(props) {
  /** Show user information about purchasing a subscription gift. */

  const [duration, setDuration] = useState(null);
  const { addToast } = useToasts();
  const [createGiftPurchase, result] = useCreateGiftPurchaseMutation();
  const redirectToCheckout = useRedirectToCheckout();

  useEffect(() => {
    if (result.isSuccess) redirectToCheckout({ sessionId: result.data.id });
    if (result.isError)
      addToast("There was an error initializing the gift purchase.", {
        appearance: "error",
      });
  }, [result]);

  async function handleContinue() {
    if (duration) {
      createGiftPurchase({ duration });
    } else {
      addToast("Please choose a duration for the subscription gift", {
        appearance: "error",
      });
    }
  }

  const durationChoices = [
    {
      id: 3,
      label: "3 months ($99)",
    },
    {
      id: 6,
      label: "6 months ($198)",
    },
    {
      id: 12,
      label: "1 year ($299)",
    },
  ];

  function renderInfoBlock() {
    let text = "";

    if ([3, 6].includes(duration)) {
      text = "This coupon can be used for monthly subscriptions";
    } else if (duration === 12) {
      text = "This coupon can be used for annual subscriptions";
    }

    if (text) {
      return (
        <div className="d-flex align-items-center justify-content-center">
          <img src={InfoImage} />
          <Body className="ml-1">{text}</Body>
        </div>
      );
    } else {
      return null;
    }
  }

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Purchase subscription gift"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <div>
          <ModalSummaryText>
            Choose the duration of the subscription gift you'd like to purchase
            and you'll be given a redemption code to be used with any new or
            existing Project City subscription.
          </ModalSummaryText>

          <ChoiceContainer
            selected={duration}
            setSelected={setDuration}
            choices={durationChoices}
          />

          <div className="d-flex align-items-center justify-content-center mt-3">
            <img src={InfoImage} />
            <Body className="ml-1">Gift purchases are non-refundable</Body>
          </div>

          {renderInfoBlock()}

          <FormSubmitContainer>
            <FormSecondaryButton
              onClick={props.onHide}
              disabled={result.isLoading}
            >
              Cancel
            </FormSecondaryButton>
            <FormPrimaryButton
              onClick={handleContinue}
              disabled={result.isLoading}
            >
              Continue to payment
            </FormPrimaryButton>
          </FormSubmitContainer>
        </div>
      </Modal.Body>
    </BaseModal>
  );
}

export default SubscriptionGiftModal;
