import styled from "styled-components";
import { Link } from "@reach/router";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isMobile } from "react-device-detect";

import CopyHeader from "components/text/CopyHeader";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import UnorderedList from "components/lists/UnorderedList";
import ListItem from "components/lists/ListItem";
import SubscriptionButtonsContainer from "features/subscriptions/components/ButtonContainer";

const AboutContainer = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    width: 80%;
    margin-left: 10%;
    margin-top: ${(props) => props.theme.vSpacingXl};
    margin-bottom: ${(props) => props.theme.vSpacingXl};
  }

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    margin-top: ${(props) => props.theme.vSpacingMd};
    margin-bottom: ${(props) => props.theme.vSpacingMd};
    flex-direction: column-reverse;
  }
`;

const AboutCol = styled(Col)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const SubscribeSection = styled(Col)`
  position: relative;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-top: ${(props) => props.theme.vSpacingMd};
  }
`;

const StyledVideo = styled.video`
  margin-bottom: ${(props) => props.theme.vSpacingMd};
  padding: 10px;
`;

function SubscriptionContentContainer() {
  /** Show the user a section pushing them towards a subscription. */

  return (
    <div id="subscription-info-section" className="text-center">
      <CopyHeader className="mt-4">
        Project City - Story Sprint - Animation School
      </CopyHeader>
      <AboutContainer>
        <AboutCol>
          <SubHeaderText color="purple">about</SubHeaderText>
          <UnorderedList>
            <ListItem>New live and recorded 15 min classes every week</ListItem>
            <ListItem>All classes taught by industry pros</ListItem>
            <ListItem>New teachers each month (view schedule)</ListItem>
            <ListItem>Assignments to help build your portfolio</ListItem>
            <ListItem>Learn by making your own show</ListItem>
            <ListItem>
              Make friends, share work and create together in the Story Sprint
              discord
            </ListItem>
            <ListItem>
              Gain access to all 400+ previous class recordings
            </ListItem>
            <ListItem>
              Get 20 free coins per month to donate to students making their own
              shows (cash not crypto)
            </ListItem>
            <ListItem>
              Select students can collect coins when posting animatic episodes
              of their show
            </ListItem>
            <ListItem>
              Bonus: gain access to a sampling of Project City long-form class
              recordings (note: long form classes sold separately{" "}
              <Link to="/classes/live">here</Link>)
            </ListItem>
          </UnorderedList>
          <SubHeaderText tilt color="orange" className="mt-3">
            Better than any art school, for a fraction of the price
          </SubHeaderText>
        </AboutCol>
        <SubscribeSection>
          <SubHeaderText tilt color="pink" size="lg" italic>
            Black Friday Sale!!!
          </SubHeaderText>

          <CopyHeader color="pink" className="mt-3">
            Use promo code “BLACKFRIDAY23” at checkout to get the discount.
          </CopyHeader>

          <CopyHeader color="pink" className="mt-3 mb-3">
            SALE STARTS: 11/24 @ 12:01am PST<br/>
            SALE ENDS: 11/26 @ 11:59pm PST
          </CopyHeader>

          <SubscriptionButtonsContainer />
        </SubscribeSection>
      </AboutContainer>
    </div>
  );
}

export default SubscriptionContentContainer;
