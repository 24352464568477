import ProjectCityButton from "components/buttons/Button";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import SubscribeButton from "features/sprint/components/SubscribeButton";
import SubscriptionGiftModal from "features/subscriptions/modals/Gift";
import useAuthenticationModal from "hooks/AuthModal";
import useIsAuthenticated from "hooks/IsAuthenticated";
import { useModal } from "react-modal-hook";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
    gap: ${(props) => props.theme.vSpacingMd};
  }
`;

function SubscriptionButtonsContainer() {
  /** Container to show the subscription buttons. */

  const isAuthenticated = useIsAuthenticated();

  const [showGiftModal, hideGiftModal] = useModal(() => (
    <SubscriptionGiftModal onHide={hideGiftModal} />
  ));
  const showAuthModal = useAuthenticationModal();

  return (
    <>
      <Wrapper>
        <div>
          <SubHeaderText color="yellow" size="sm">$33 monthly</SubHeaderText>
          <SubHeaderText color="yellow" size="sm">&nbsp;</SubHeaderText>
          <SubscribeButton className="m-2 px-4 d-block" />
        </div>
        <div>
          <SubHeaderText color="yellow" size="sm">12 months for $299{/*$363 yearly*/}</SubHeaderText>
          <SubHeaderText strikethrough color="yellow" size="sm">$363 yearly</SubHeaderText>
          <SubscribeButton className="m-2 px-4 d-block" isAnnual />
        </div>
      </Wrapper>
      <ProjectCityButton
        variant="outline-secondary"
        className="mt-3"
        noTilt
        onClick={isAuthenticated ? showGiftModal : showAuthModal}
      >
        Purchase as a gift
      </ProjectCityButton>
    </>
  );
}

export default SubscriptionButtonsContainer;
