import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useModal } from "react-modal-hook";
import ReactPixel from "react-facebook-pixel";
import { parse as queryStringParse } from "query-string";
import { navigate } from "@reach/router";
import { isMobile } from "react-device-detect";
import { useToasts } from "react-toast-notifications";

import NewSubscriberModal from "features/subscriptions/modals/NewSubscriber";
import Header from "components/text/Header";
import NewUserTour from "features/tours/components/NewUser";
import SubscriptionVideoList from "features/sprint/components/ContentUploadList";
import { useCanViewSprintSubscription } from "features/sprint/hooks";
import SubscriptionInfo from "features/sprint/components/SubscriptionContainer";
import SprintHeaderContainer from "features/sprint/components/HeaderContainer";
import ContentUploadIcon from "features/sprint/components/UploadIcon";
import ContentUploadModal from "features/sprint/modals/ContentUploadModal";
import SprintWrapper from "features/sprint/components/Wrapper";
import { tourType } from "features/tours/enums";
import NewSubscriberTour from "features/tours/components/NewSubscriber";
import useIsAuthenticated from "hooks/IsAuthenticated";
import projectCityApi, {
  useGetContentUploadListQuery,
} from "services/projectCity";
import Sticker from "components/icons/Sticker";
import { contentUploadType } from "features/sprint/enums";
import { setContentUploads } from "features/sprint/slice";
import SprintFilterBar from "features/sprint/components/FilterBar";
import { canUploadPremiumContent } from "utils/permissions";
import SubHeader from "components/text/SubHeader";
import ChooseCategoryModal from "features/sprint/modals/ChooseCategory";
import GiftPurchaseCompleteModal from "features/subscriptions/modals/GiftComplete";
import SubscriptionGiftModal from "features/subscriptions/modals/Gift";
import SubscriptionBlackFridayModal from "features/subscriptions/modals/BlackFriday";
import ProjectCityButton from "components/buttons/Button";

const StyledSticker = styled(Sticker)`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 50%;
`;

const GiftPurchaseButton = styled(ProjectCityButton)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

const BlackFridayButton = styled(ProjectCityButton)`
  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    margin-bottom: ${(props) => props.theme.vSpacingMd};
  }
`;

function SprintVideoListRoute({ location }) {
  /** Display the sprint lesson videos to our subscribers. */

  const isAuthenticated = useIsAuthenticated();
  const user = useSelector((state) => state.account.user);
  const dispatch = useDispatch();
  const { filters: params } = useSelector((state) => state.contentUploads);
  const hasSubscriptionAccess = useCanViewSprintSubscription();
  const [createdContentUpload, setCreatedContentUpload] = useState(null);
  const { addToast } = useToasts();

  const { data, isLoading, isError, isFetching } = useGetContentUploadListQuery(
    params,
    {
      skip: !isAuthenticated,
    }
  );

  const queryStrings = queryStringParse(location.search, {
    parseBooleans: true,
  });

  const [showGiftModal, hideGiftModal] = useModal(() => (
    <SubscriptionGiftModal onHide={hideGiftModal} />
  ));

  const [showBlackFridayModal, hideBlackFridayModal] = useModal(() => (
    <SubscriptionBlackFridayModal onHide={hideBlackFridayModal} />
  ));

  useEffect(() => {
    if (queryStrings.giftPurchase === true) {
      showGiftPurchaseCompleteModal();
    } else if (queryStrings.giftPurchase === false) {
      addToast("Gift purchase canceled", { appearance: "info" });
    }
  }, []);

  const [
    showGiftPurchaseCompleteModal,
    hideGiftPurchaseCompleteModal,
  ] = useModal(() => (
    <GiftPurchaseCompleteModal onHide={hideGiftPurchaseCompleteModal} />
  ));

  const [showNewSubscriberModal, hideNewSubscriberModal] = useModal(() => (
    <NewSubscriberModal onHide={hideNewSubscriberModal} />
  ));

  // Modal to give the user a 2nd chance of assigning a category to their newly created lesson.
  const [showChooseCategoryModal, hideChooseCategoryModal] = useModal(
    () => (
      <ChooseCategoryModal
        onHide={hideChooseCategoryModal}
        contentUploadId={createdContentUpload.id}
      />
    ),
    [createdContentUpload]
  );

  const [
    fetchNextPage,
    { isLoading: isNextDataLoading },
  ] = projectCityApi.endpoints.getNextContentUploadPage.useLazyQuery();

  useEffect(() => {
    /** Set the redux state to be what's shown as the filtered data. */
    dispatch(
      setContentUploads(
        data?.results.filter((contentUpload) => {
          // Only return content uploads with a video.
          return contentUpload.file !== null;
        }) || []
      )
    );
  }, [data]);

  useEffect(() => {
    if (queryStrings.purchase) {
      ReactPixel.track("Subscribe", {
        currency: "USD",
        value: parseFloat(33.0),
        predicted_ltv: parseFloat(168.32),
      });
      showNewSubscriberModal();
      navigate("/sprint/content");
    }
  }, []);

  function handlePromptChooseCategory(contentUpload) {
    setCreatedContentUpload(contentUpload);
    showChooseCategoryModal();
  }

  const canUploadLesson = canUploadPremiumContent(user);
  const [showCreateContentModal, hideCreateContentModal] = useModal(() => (
    <ContentUploadModal
      onHide={hideCreateContentModal}
      contentUpload={{
        type: canUploadLesson
          ? contentUploadType.lesson
          : contentUploadType.animatic,
        inSprint: true,
      }}
      chooseInSprint={false}
      chooseBucket
      chooseType={canUploadLesson}
      title={canUploadLesson ? "New content upload" : "New animatic"}
      promptChooseCategory={handlePromptChooseCategory}
    />
  ));

  const shouldShowNewUserTour =
    isAuthenticated && !isMobile && !user.tours.includes(tourType.newUser);
  
  const shouldShowNewSubscriberTour =
    isAuthenticated &&
    !shouldShowNewUserTour &&
    !isMobile &&
    !user.tours.includes(tourType.newSubscription);

  return (
    <SprintWrapper>
      {shouldShowNewUserTour && <NewUserTour />}
      {shouldShowNewSubscriberTour && <NewSubscriberTour />}
      <SprintHeaderContainer>
        {hasSubscriptionAccess ? (
          <div>
            <ContentUploadIcon onClick={showCreateContentModal} />
            {user?.subscription?.interval === "month" ? (
              <BlackFridayButton
                isWavy
                variant="info"
                className="mt-3"
                onClick={showBlackFridayModal}
                startTilt
              >
                Black Friday Sale!!!
              </BlackFridayButton>
            ) : (
              <GiftPurchaseButton
                isWavy
                variant="info"
                className="mt-3"
                onClick={showGiftModal}
                startTilt
              >
                Purchase sprint gift
              </GiftPurchaseButton>
              )}
          </div>
        ) : (
          <div />
        )}
        <Header
          color="orange"
          top="story sprint"
          bottom="exclusive videos"
          className="mb-5 text-center"
        />
        <div />
        <StyledSticker />
      </SprintHeaderContainer>
      {hasSubscriptionAccess ? <SprintFilterBar /> : <SubscriptionInfo />}
      {hasSubscriptionAccess && (
        <div>
          {!hasSubscriptionAccess && (
            <SubHeader color="pink" className="text-center w-100">
              Here are some sample lessons, subscribe to get the full set!
            </SubHeader>
          )}
          <SubscriptionVideoList
            allowChooseBucket
            isError={isError}
            data={data?.results}
            fetchNextPage={() => fetchNextPage({ next: data?.next, ...params })}
            hasMore={data?.next !== null}
            isLoading={isLoading || isFetching}
            isNextDataLoading={isNextDataLoading}
          />
        </div>
      )}
    </SprintWrapper>
  );
}

export default SprintVideoListRoute;
