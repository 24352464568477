import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import { parse as queryStringParse } from "query-string";
import { useToasts } from "react-toast-notifications";
import moment from "moment";
import { useModal } from "react-modal-hook";
import Alert from "react-bootstrap/Alert";

import Header from "components/text/Header";
import InfoTooltip from "components/tooltips/Info";
import DashboardContainer from "components/containers/Dashboard";
import Body from "components/text/Body";
import SubHeaderText from "components/text/SubHeader/TextOnly";
import LoadingContainer from "components/loading/Container";
import ProjectCityButton from "components/buttons/Button";
import CancelSubscriptionModal from "features/sprint/modals/CancelSubscription";
import SubscribeButton from "features/sprint/components/SubscribeButton";
import AnnualConversionModal from "features/subscriptions/modals/AnnualConversion";
import SubscriptionGiftModal from "features/subscriptions/modals/Gift";
import GiftPurchaseCompleteModal from "features/subscriptions/modals/GiftComplete";
import ApplyCouponModal from "features/subscriptions/modals/ApplyCoupon";
import {
  useCreateUpdatePaymentMethodSessionMutation,
  useGetSubscriptionDataQuery,
  useRemoveCancelSubscriptionMutation,
  useUpdatePaymentMethodFromCheckoutMutation,
  useGetSubscriptionInvoicesQuery,
} from "services/projectCity";
import { stripePublishableKey } from "utils/stripe";
import { formatTimeStamp } from "utils/datetime";
import { toCurrency } from "utils/general";
import SubscriptionBlackFridayModal from "features/subscriptions/modals/BlackFriday";

const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-row-gap: 2px;

  > * {
    border-bottom: 2px solid ${(props) => props.theme.gray2};
    padding-bottom: 10px;
    margin-top: 10px;
  }
`;

const ActionButtons = styled.div`
  display: flex;

  @media (max-width: ${(props) => props.theme.smBreakpoint}) {
    flex-direction: column;
  }

  @media (min-width: ${(props) => props.theme.smBreakpoint}) {
    align-items: center;
    justify-content: space-between;
  }
`;

const CancelButton = styled(ProjectCityButton)`
  color: ${(props) => props.theme.gray2}!important;
`;

function SubscriptionRoute(props) {
  /** Display information about a user's subscription. */

  const queryStrings = queryStringParse(props.location.search, {
    parseBooleans: true,
  });
  const user = useSelector((state) => state.account.user);
  const { addToast } = useToasts();
  const [
    showGiftPurchaseCompleteModal,
    hideGiftPurchaseCompleteModal,
  ] = useModal(() => (
    <GiftPurchaseCompleteModal onHide={hideGiftPurchaseCompleteModal} />
  ));

  useEffect(() => {
    if (queryStrings.giftPurchase === true) {
      showGiftPurchaseCompleteModal();
    } else if (queryStrings.giftPurchase === false) {
      addToast("Gift purchase canceled", { appearance: "info" });
    }
  }, []);

  const [
    updatePaymentMethodFromCheckout,
    { isLoading: isUpdatePaymentMethodLoading },
  ] = useUpdatePaymentMethodFromCheckoutMutation();

  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchSubscription,
  } = useGetSubscriptionDataQuery(
    {
      subscriptionId: user?.subscription?.subscriptionId,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: user?.subscription === null,
    }
  );

  const {
    data: invoices,
    isLoading: invoicesAreLoading,
  } = useGetSubscriptionInvoicesQuery(
    {
      subscriptionId: user?.subscription?.subscriptionId,
    },
    {
      skip: user?.subscription === null,
    }
  );

  const [
    removeCancelSubscription,
    { isLoading: isRemoveCancelLoading },
  ] = useRemoveCancelSubscriptionMutation();

  const [
    showConfirmCancelSubscriptionModal,
    hideConfirmCancelSubscriptionModal,
  ] = useModal(
    () => (
      <CancelSubscriptionModal onHide={hideConfirmCancelSubscriptionModal} />
    ),
    [user.subscription?.subscriptionId]
  );

  const [
    createUpdatePaymentMethodSession,
    { data: checkoutData, status },
  ] = useCreateUpdatePaymentMethodSessionMutation();

  useEffect(() => {
    if (status === "fulfilled") redirectToStripeCheckout();
  }, [status]);

  useEffect(() => {
    // Update the payment method if the checkout session is complete.
    if (queryStrings.session_id) {
      updatePaymentMethodFromCheckout({ sessionId: queryStrings.session_id });
    }
  }, []);

  const hasCancellationRequest =
    data?.status === "active" && data.cancelAtPeriodEnd;

  const [showConversionModal, hideConversionModal] = useModal(() => (
    <AnnualConversionModal onHide={hideConversionModal} />
  ));

  const [showBlackFridayModal, hideBlackFridayModal] = useModal(() => (
    <SubscriptionBlackFridayModal onHide={hideBlackFridayModal} />
  ));

  const [showGiftModal, hideGiftModal] = useModal(() => (
    <SubscriptionGiftModal onHide={hideGiftModal} />
  ));

  const [showApplyCouponCodeModal, hideApplyCouponCodeModal] = useModal(() => (
    <ApplyCouponModal
      onHide={hideApplyCouponCodeModal}
      refetchSubscription={refetchSubscription}
    />
  ));

  function renderActionButton() {
    console.log(data);
    /** Render different buttons depending on the user's subscription status. */
    if (data.status === "active" && !data.cancelAtPeriodEnd)
      return (
        <ActionButtons>
          <CancelButton
            onClick={showConfirmCancelSubscriptionModal}
            variant="link"
            noTilt
          >
            Cancel Subscription
          </CancelButton>
          <ProjectCityButton variant="secondary" onClick={showGiftModal}>
            Purchase Gift
          </ProjectCityButton>
          {data?.plan?.interval === "month" && (
            <ProjectCityButton variant="info" onClick={showBlackFridayModal}>
              Upgrade to Annual
            </ProjectCityButton>
          )}
        </ActionButtons>
      );

    if (hasCancellationRequest)
      return (
        <ProjectCityButton
          onClick={() =>
            removeCancelSubscription({
              subscriptionId: user.subscription.subscriptionId,
            })
          }
          disabled={isRemoveCancelLoading}
          variant="info"
          noTilt
        >
          Wait, don't cancel!
        </ProjectCityButton>
      );

    if (data.status === "canceled")
      return <SubscribeButton buttonText="Reactivate Subscription" />;
  }

  async function redirectToStripeCheckout() {
    const stripeClient = await loadStripe(stripePublishableKey);
    await stripeClient.redirectToCheckout({
      sessionId: checkoutData.id,
    });
  }

  function renderInvoices() {
    return invoices.map((invoice) => {
      return (
        <React.Fragment key={invoice.id}>
          <Body>{moment.unix(invoice.created).format("MMM D, yyyy")}</Body>
          <Body>{toCurrency(invoice.amountDue / 100)}</Body>
          <Body>{invoice.status}</Body>
        </React.Fragment>
      );
    });
  }

  function renderContent() {
    if (isUpdatePaymentMethodLoading) {
      return <LoadingContainer text="Finishing payment method update..." />;
    }

    if (isLoading || isFetching)
      return <LoadingContainer text="Loading subscription data..." />;

    function renderIntervalDisplay() {
      /** Show display value based on monthly or yearly subscription interval. */
      if (data.plan.interval === "month") return "monthly";
      else return "yearly";
    }

    const shouldDisplayRenew = !hasCancellationRequest;
    const hasCoupon = data?.discount?.coupon?.id;

    function renderCouponDescription() {
      return (
        <>
          <span className="pr-1">
            {`${data.discount.coupon.name} (expires ${formatTimeStamp(
              data.discount.end,
              "M/D/YYYY"
            )})`}
          </span>
          <InfoTooltip text="No charges will incur until after the coupon expires" />
        </>
      );
    }

    if (data === undefined) {
      return (
        <Alert variant="info" className="mt-5 text-center">
          Problem loading subscription page, you might not have a subscription.
        </Alert>
      );
    }

    return (
      <div className="mt-5">
        <SubHeaderText color="green">subscription data</SubHeaderText>
        <DataWrapper>
          <Body>Status:&nbsp;</Body>
          <Body>
            {hasCancellationRequest ? "cancellation pending" : data.status}
          </Body>
          <div />

          {shouldDisplayRenew && (
            <>
              <Body>Renews:&nbsp;</Body>
              <Body>{renderIntervalDisplay()}</Body>
              <div />
            </>
          )}

          {["active", "past_due"].includes(data.status) && (
            <>
              {data.status === "active" && (
                <>
                  <Body>
                    {data.cancelAtPeriodEnd
                      ? "Cancellation date:"
                      : "Next bill date:"}
                    &nbsp;
                  </Body>
                  <Body>
                    {moment.unix(data.currentPeriodEnd).format("MMM DD, YYYY")}
                  </Body>
                  <div />
                </>
              )}
              <>
                <>
                  <Body>Coupon:</Body>
                  <Body>{hasCoupon ? renderCouponDescription() : "None"}</Body>
                  {hasCoupon ? (
                    <div />
                  ) : (
                    <div>
                      <ProjectCityButton
                        noTilt
                        variant="link"
                        size="sm"
                        onClick={showApplyCouponCodeModal}
                      >
                        Apply coupon
                      </ProjectCityButton>
                    </div>
                  )}
                </>
              </>
              <>
                <Body>Card:&nbsp;</Body>
                {data.defaultPaymentMethod?.card?.last4 ? (
                  <Body>
                    <span>
                      **** **** **** {data.defaultPaymentMethod.card.last4}
                    </span>
                  </Body>
                ) : (
                  <Body>
                    <span className="mr-1">No payment method</span>
                    <InfoTooltip text="Add a payment method to continue your subscription" />
                  </Body>
                )}
                <div>
                  <ProjectCityButton
                    noTilt
                    variant="link"
                    size="sm"
                    onClick={createUpdatePaymentMethodSession}
                  >
                    Change
                  </ProjectCityButton>
                </div>
              </>
            </>
          )}
        </DataWrapper>

        <div className="mt-5">
          <SubHeaderText color="green">payment history</SubHeaderText>
          {invoicesAreLoading ? (
            <LoadingContainer text="loading invoices..." />
          ) : (
            <DataWrapper>{renderInvoices()}</DataWrapper>
          )}
        </div>

        <div className="mt-5">{renderActionButton()}</div>
      </div>
    );
  }

  return (
    <DashboardContainer>
      <Header color="pink" top="manage my" bottom="subscription" />
      {renderContent()}
    </DashboardContainer>
  );
}

export default SubscriptionRoute;
