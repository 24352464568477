import { Modal } from "react-bootstrap";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import LoadingContainer from "components/loading/Container";
import FormSubmitContainer from "components/forms/shared/SubmitContainer";
import FormSecondaryButton from "components/buttons/forms/FormSecondary";
import FormPrimaryButton from "components/buttons/forms/FormPrimary";
import {
  useUpgradePreviewQuery,
  useUpgradeToAnnualMutation,
} from "services/projectCity";
import { toCurrency } from "utils/general";
import { useState } from "react";

function AnnualConversionModal(props) {
  /** Show the user a preview and confirmation to switch from monthly to annual. */

  const [
    upgradeSubscription,
    { isLoading: upgradeIsLoading },
  ] = useUpgradeToAnnualMutation();
  const { data, isLoading } = useUpgradePreviewQuery();
  const [errorText, setErrorText] = useState(null);

  async function handleUpgradeClick() {
    setErrorText(null);
    const checkoutData = await upgradeSubscription();

    if (checkoutData.error) {
      if (checkoutData.error?.data?.detail) {
        setErrorText(checkoutData.error.data.detail);
      } else {
        setErrorText(
          "There was an error converting your subscription to annual. If the error persists, please contact support."
        );
      }
    } else {
      window.location.reload();
    }
  }

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Black Friday Sale!!!"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        {isLoading ? (
          <LoadingContainer />
        ) : (
          <div>
            <ModalSummaryText className="mb-2">
              Enjoy two months free of Project City Story Sprint when you switch to an annual plan!
            </ModalSummaryText>
            <ModalSummaryText className="mb-2">
              Your monthly subscription will be converted into an annual subscription and you will be charged{" "}
              {toCurrency(data.amountDue / 100)}. This is less than the full annual cost of $363 because you are receiving 
              one year of the Sprint for a Black Friday sale price of $299 - PLUS - you are being credited for time 
              on your current monthly subscription.
            </ModalSummaryText>
            <ModalSummaryText>
              This double savings is our way of saying THANK YOU for being a loyal founding member of the Project City community!!!
            </ModalSummaryText>
            <FormSubmitContainer errorText={errorText}>
              <FormSecondaryButton
                disabled={upgradeIsLoading}
                onClick={props.onHide}
              >
                Cancel
              </FormSecondaryButton>
              <FormPrimaryButton
                disabled={upgradeIsLoading}
                onClick={handleUpgradeClick}
              >
                Switch to Annual
              </FormPrimaryButton>
            </FormSubmitContainer>
          </div>
        )}
      </Modal.Body>
    </BaseModal>
  );
}

export default AnnualConversionModal;
