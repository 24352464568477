import { Modal } from "react-bootstrap";

import BaseModal from "components/modals/Base";
import ModalHeader from "components/modals/Header";
import ModalSummaryText from "components/text/ModalSummaryText";
import UnorderedList from "components/lists/UnorderedList";

function NewSubscriberModal(props) {
  // Show some information to a user that has just subscribed.

  return (
    <BaseModal size="lg" {...props}>
      <ModalHeader
        title="Welcome to the Story Sprint!"
        closeButton
        closeModal={props.onHide}
      />
      <Modal.Body>
        <ModalSummaryText>
          <p>
            You now have access to every Sprint video uploaded since the
            beginning of the Story Sprint.
          </p>
          <p>New videos will appear daily (on weekdays).</p>
          <p>
            Be sure to join the{" "}
            <a href="https://discord.gg/DVYUH2unVx">Story Sprint discord</a>!
          </p>
          <p>Here are some quick tips to get you started:</p>

          <UnorderedList>
            <li>
              Links to live classes will be posted on the content page the day
              of class.
            </li>
            <li>
              You can use the filter dropdowns to find the content you're
              interested in most.
            </li>
            <li>
              We suggest starting with the videos in the “Where to Start”
              category.
            </li>
            <li>
              On the schedule page you can keep track of when new live and
              recorded lessons will be dropping.
            </li>
            <li>
              Some videos have assignments in red to the right of the video. You
              can also go directly to the assignments page to see all
              assignments from the Sprint.
            </li>
            <li>
              As you complete and upload assignments, a check mark will appear
              to mark your progress.
            </li>
            <li>
              You can post your own storyboard animatics to the animatics
              section! Press the plus button in the upper left to upload.
            </li>
          </UnorderedList>

          <p>
            For any other questions you can check out our{" "}
            <a href="https://projectcity.theclientdemos.com/faq">FAQ</a>.
          </p>
          <p>
            If you don't see your question answered in the FAQ, you can always
            reply to us here.
          </p>
        </ModalSummaryText>
      </Modal.Body>
    </BaseModal>
  );
}

export default NewSubscriberModal;
